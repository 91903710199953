import {
  createSignal,
  Show,
  For,
  createEffect,
  Switch,
  Match,
  createResource,
  onMount,
  onCleanup,
  Suspense,
  ErrorBoundary,
} from "solid-js";
import { isServer } from "solid-js/web";
import { useSearchParams, A, createAsync, useNavigate } from "@solidjs/router";
import { createStore, produce } from "solid-js/store";
import { debounce } from "@solid-primitives/scheduled";
import {
  useErrorContext,
  useSessionContext,
  useSiteContext,
} from "~/utils/contexts";
import algoliasearch from "algoliasearch";
import { PT } from "~/utils/products";
import { PERMISSION } from "~/services/roma-api/account/types";
import { EventType } from "@solid-primitives/analytics";
import { ItemOrderContext } from "~/utils/contexts";
import { Motion } from "solid-motionone";
import { Icon } from "solid-heroicons";
import {
  chevronLeft,
  chevronDown,
  magnifyingGlass,
  questionMarkCircle,
} from "solid-heroicons/solid";
import { BaseLoader } from "~/components/utility";
import { mergeAsNum } from "~/utils/fraction";
import { capitalize } from "~/utils/helpers";

import { TextFieldInput } from "~/components/inputs";

import { InfoBox } from "~/components/utility";
import {
  StoreValuesHelper,
  TypeSelector,
  ProductPlaceholder,
  ProductThumbnail,
  StackSearchTwo,
  StackSearchThree,
  QtyCounter,
  TagName,
  BackOrderAcknowledge,
  CancelBackOrderAcknowledge,
  DiscontinuedAcknowledge,
  DiscontinuedStackWarning,
  FreightChargeAcknowledge,
  OrderSummary,
} from "~/components/ordering/order-page";
import {
  cuttingInstrOptions,
  Length,
  Contract,
  Join,
  Chop,
  Rail,
  PhotoFrame,
  GalleryFrame,
  CornerSample,
  Stacked,
} from "~/components/ordering/order-page/product-types";
import Body from "~/components/Body";

import type { AlgoliaMouldingResult } from "~/utils/types";
import type { CartDetail } from "~/services/roma-api/cart/types";
import {
  ChopLineItem,
  StackLineItem,
} from "~/services/roma-api/cart/types-lineItems";
import {
  AllowanceOption,
  BevelOption,
  CuttingOption,
  OrderProductTypes,
  ORIENTATION,
  OrientationOption,
  WedgeOption,
} from "~/services/roma-api/cart/types-productOrdering";
import { getDefaultStore } from "~/components/ordering/order-page/utils";
import { GenericError } from "~/components/utility/errors";
import { checkError, simulateApiError } from "~/services/roma-api/errors";

type OrderPageParams = {
  orientation?: OrientationOption;
  shipsFrom?: string;
  type?: `${PT}`;
  editCart?: any;
  sku?: string;
  size?: string;
};

export default function OrderPage() {
  const { session, isPartner, permission, clearSession } = useSessionContext();
  const { track, setPanel } = useSiteContext();
  const { addError } = useErrorContext();
  const [params, setParams] = useSearchParams<OrderPageParams>();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = createSignal("");
  const [showSearchResults, setShowSearchResults] = createSignal(false);
  const [showStoreValues, setShowStoreValues] = createSignal(false); // * DEBUGGING

  const search = debounce((value: string) => {
    setSearchValue(value);
    track(EventType.Event, {
      category: "search",
      // @ts-expect-error
      search_term: value,
    });
  }, 500);

  const index = !isServer
    ? algoliasearch(
        import.meta.env.VITE_ALGOLIA_APP_ID,
        import.meta.env.VITE_ALGOLIA_API_KEY
      ).initIndex(import.meta.env.VITE_ALGOLIA_INDEX)
    : null;

  const [products] = createResource(
    () => (searchValue() == "" || !index ? false : searchValue()),
    async (searchValue) => {
      try {
        const response = await index?.search<AlgoliaMouldingResult>(
          searchValue,
          {
            hitsPerPage: 100,
            attributesToRetrieve: [
              "sku",
              "name",
              "width",
              "height",
              "rabbet",
              "category",
              "collection",
              "color_description",
              "available_as",
              "product_type",
              "discontinued",
              "discontinued_discount",
              "is_floater",
              "profile",
            ],
          }
        );

        return {
          Total: response?.hits.length,
          Results: response?.hits.map((hit) => ({
            SKU: hit.objectID,
            Width: hit.width,
            Height: hit.height,
            Rabbet: hit.rabbet,
            Name: hit.name,
            Collection: hit.collection,
            Category: hit.category,
            Profile: hit.profile,
            ColourDescription: hit.color_description,
            AvailableAs: hit.available_as,
            Discontinued: hit.discontinued,
            DiscontinuedDiscount: hit.discontinued_discount,
            Floater: hit.is_floater,
            ProductType: hit.product_type,
          })),
        };
      } catch (error) {
        const err = checkError(error);
        if (import.meta.env.DEV) {
          console.log(
            "[(order).tsx]: Error caught in algolia products resource: ",
            err
          );
        }
        addError(err, {
          severity: "warning",
          showDetails: true,
          title: "Product Search Error",
          message:
            "An error occurred while retrieving product information. Please try again. If the error persists, kindly contact customer support.",
          actions: [
            {
              label: "Contact Support",
              action: async () => {
                navigate("/support");
              },
            },
          ],
        });
        console.log(error);
      }
    },
    {
      initialValue: {
        Total: 0,
        Results: [],
      },
      ssrLoadFrom: "initial",
    }
  );

  const resetStoreForCartEditing = async () => {
    setOrderData({ ...getDefaultStore(), editProduct: true });
  };

  const [orderData, setOrderData] = createStore<OrderStore>({
    products: products.latest?.Results, // ? is this used, or allProducts?
    get allProducts() {
      return products.latest?.Results;
    },
    itemIndex: 0,
    get currentProduct() {
      return this.allProducts?.[this.itemIndex];
    },
    get availableAs() {
      return this.currentProduct?.AvailableAs;
    },
    get width() {
      return this.currentProduct?.Width;
    },
    get mouldingHeight() {
      return this.currentProduct?.Height;
    },
    get isDiscontinued() {
      return this.currentProduct?.Discontinued;
    },
    get isFloater() {
      return this.currentProduct?.Floater;
    },
    get isStretcher() {
      return this.currentProduct?.Profile === "Stretcher";
    },
    ...getDefaultStore(),
  });

  // TODO: check if initalValue is needed/why error is there..
  const [cart, { refetch: getCart }] = createResource<CartDetail>(
    // * Note for possible future refactor:
    // Session cart_id doesn't *need* to be reactively tracked here. Pro is that it will allow us to catch
    // the user switching their current cart while actively editing a product.
    // Alternatively could pass alongthe cart_id in the params, fetch the cart via that value
    // - This would mean user could switch carts (why?), and we submit the update to the param cart value, instead of session cart value.
    // - Then on submit/update, update the current cart to the one that was edited, open up the cart panel/go to cart page for that particular cart.
    // OR
    // - serialize both the cart_id, and the line item entirely, and populate the orderData store with that deserialized value. This would eliminate the need for this cart resource/fetch entirely?
    () => (params.editCart ? [session()?.cart_id, params.editCart] : false),
    async ([cart_id, item_id]) => {
      try {
        // bop
        if (!params.editCart) {
          console.log("line item id undefined: ", params.editCart);
          throw new Error(`line item id is undefined: ${params.editCart}`);
        }
        await resetStoreForCartEditing();

        // await simulateApiError({path: `/cart/${cart_id}`})

        const response = await fetch(
          `${import.meta.env.VITE_ROMA_API}/cart/${cart_id}`,
          {
            headers: {
              Authorization: `Bearer ${session()?.token}`,
            },
          }
        );

        if (response.ok) {
          const cart: CartDetail = await response.json();
          const index = cart.Lines.findIndex((item) => item.ID == item_id);
          if (index === -1) {
            // TODO Error handling
            // ? For testing this, change the active cart while on the edit product page
            throw new Error(
              "The item you are trying to edit was not found in your current cart."
            );
          }
          const lineItem = { ...cart.Lines[index] };

          // const lineItem = cart.Lines
          // Check if the item is not a stacked moulding
          // if (cart.Lines[params.editCart].Type !== "stack")
          if (lineItem.Type !== PT.STACK) {
            const item = { ...cart.Lines[index] };
            // Set the moulding as the search value
            setSearchValue(item.SubItems[0].Moulding);

            // Set store general data:
            // TODO - check, is this handling PT.BOX & PT.CORNERSAMPLE ? If not, define below..
            setOrderData(
              produce((data) => {
                data.selected.moulding = item.SubItems[0].Moulding;
                data.itemIndex = 0;
                data.selected.shipsFrom = item.Plant;
                data.selected.productType = item.Type;
                data.selected.stacked = false;
                data.selected.quantity = item.Quantity;
              })
            );
            if (
              item.Type === PT.JOIN ||
              item.Type === PT.CHOP ||
              item.Type === PT.MIRROR
            ) {
              setOrderData(
                produce((data) => {
                  data.selected.width = mergeAsNum(
                    item.SubItems[0].Width,
                    item.SubItems[0].WidthFraction
                  );
                  data.selected.height = mergeAsNum(
                    item.SubItems[0].Length!,
                    item.SubItems[0].LengthFraction
                  );
                  data.selected.sizing = item.SubItems[0].Layer as "I" | "O";
                  if (item.Type === PT.JOIN || item.Type === PT.CHOP) {
                    data.selected.allowance = item.Allowance;
                    if (item.SubItems[0].Orientation) {
                      data.selected.orientation = item.SubItems[0].Orientation;
                    }
                  }
                  if (item.Type === PT.CHOP) {
                    // const i = item as ChopLineItem
                    data.selected.wedgeLock = item.SubItems[0].Wedge;
                  }
                  if (item.Type === PT.MIRROR) {
                    data.selected.glassType = item.Bevel;
                  }
                })
              );
            } else if (item.Type === PT.RAIL) {
              setOrderData(
                produce((data) => {
                  data.selected.railLength = mergeAsNum(
                    item.SubItems[0].Length,
                    item.SubItems[0].LengthFraction
                  );
                  data.selected.allowance = item.Allowance;
                  data.selected.sizing = item.SubItems[0].Layer as "I" | "O";
                  if (item.SubItems[0].Orientation) {
                    data.selected.orientation = item.SubItems[0].Orientation;
                  }
                })
              );
            } else if (item.Type === PT.LENGTH) {
              const optionsExcludingOther = cuttingInstrOptions
                .slice(0, -1)
                .map((item) => {
                  return item.value;
                });

              setOrderData(
                produce((data) => {
                  data.selected.linearFt = item.SubItems[0].Length;
                  data.selected.cuttingInstr = optionsExcludingOther.includes(
                    item.Cutting
                  )
                    ? item.Cutting
                    : "other";
                  data.selected.specialCuttingInstr =
                    optionsExcludingOther.includes(item.Cutting)
                      ? ""
                      : item.Cutting;
                  if (item.Cutting === "custom" && !!item.CuttingPositions) {
                    data.selected.cuttingPositions = item.CuttingPositions;
                  }
                })
              );
            } else if (item.Type === PT.CONTRACT) {
              setOrderData("selected", "linearFt", item.SubItems[0].Length);
            } else if (item.Type === PT.PHOTOFRAME) {
              setOrderData(
                "selected",
                item.Type,
                item.SubItems[0].SKU.split("-")[1]
              );
            } else if (item.Type === PT.GALLERYFRAME) {
              setOrderData(
                "selected",
                item.Type,
                item.SubItems[0].SKU.split("-")[1].split("M")[0]
              );
            }
          } else if (lineItem.Type === PT.STACK) {
            const item = [...cart.Lines[index].SubItems].reduce(
              (memo, item) => {
                if (item.Layer === "O") {
                  memo.stacks.outside = item;
                } else if (item.Layer === "I") {
                  memo.stacks.inside = item;
                } else if (item.Layer === "2") {
                  memo.stacks.middle = item;
                }
                // if any of the layers are wedged,
                if (item.Wedge) {
                  memo.Wedged = "TRUE";
                }
                return memo;
              },
              {
                ...cart.Lines[index],
                stacks: {} as Record<string, any>,
                Wedged: false as false | string, //TODO - fix this, where am I relying on false or 'TRUE' ?
              }
            ) as StackLineItem & {
              stacks: Record<
                "outside" | "inside" | "middle",
                StackLineItem["SubItems"][number]
              >;
              Wedged: WedgeOption | "TRUE" | undefined; //TODO - fix this, where am I relying on 'TRUE' ?
            };

            setSearchValue(item.stacks.inside.Moulding);

            setOrderData(
              produce((data) => {
                data.selected.moulding = item.stacks.inside.Moulding;
                data.itemIndex = 0;
                data.selected.shipsFrom = item.Plant;
                data.selected.stackCount = item.SubItems.length;
                data.selected.stacked = true;
                data.selected.productType =
                  item.Type +
                  capitalize(item.Operation) +
                  item.SubItems.length.toString();
                data.selected.stacks.outsideDetails.sku =
                  item.stacks.outside.Moulding;
                if (item.SubItems.length == 3) {
                  data.selected.stacks.middleDetails.sku =
                    item.stacks.middle.Moulding;
                }
                data.selected.sizing =
                  item.FrameSize?.Sizing == "outside" ? "O" : "I";
                data.selected.width = mergeAsNum(
                  item.FrameSize?.Width,
                  item.FrameSize?.WidthFraction
                );
                data.selected.height = mergeAsNum(
                  item.FrameSize.Length,
                  item.FrameSize?.LengthFraction
                );
                data.selected.allowance = item.Allowance;
                data.selected.wedgeLock = item.Wedged;
              })
            );
          }
          if (lineItem.Tag) {
            setOrderData("selected", "tagName", lineItem.Tag);
          }
          return cart;
        } else {
          // TODO Error handling
          throw new Error("Error retrieving cart details.");
        }
      } catch (error) {
        // HERE
        const err = checkError(error);
        if (import.meta.env.DEV) {
          console.log(
            "[(order).tsx]: Error caught in cart resource for editCart operation:  ",
            err
          );
        }
        addError(err, {
          severity: "critical",
          showDetails: true,
          title: "Cart/Item Retrieval Error",
          message:
            "An error occurred while retrieving your cart/order information. Please try again. If the error persists, kindly contact customer support. We apologize for the inconvenience.",
          suggestionsLabel: "Possible Causes / Suggestions",
          suggestions: [
            "If you are adding to/editing this cart on another device/browser, it may not be synchronized",
            "The item you are trying to edit may not exist in your current cart",
            "Navigate away from this page, open the mini-cart, and ensure the correct cart is selected, then retry editing this item",
            "Sign out of your account and sign back in",
          ],
          actions: [
            {
              label: "Sign Out",
              action: async () => {
                await clearSession();
                navigate("/");
              },
            },
            {
              label: "Contact Support",
              action: async () => {
                navigate("/support");
              },
            },
          ],
        });
      }

      // if (!params.editCart) {
      //   console.log("line item id undefined: ", params.editCart);
      //   throw new Error(`line item id is undefined: ${params.editCart}`);
      // }
      // await resetStoreForCartEditing();
      // const response = await fetch(
      //   `${import.meta.env.VITE_ROMA_API}/cart/${cart_id}`,
      //   {
      //     headers: {
      //       Authorization: `Bearer ${session()?.token}`,
      //     },
      //   }
      // );

      // if (response.ok) {
      //   const cart: CartDetail = await response.json();
      //   const index = cart.Lines.findIndex((item) => item.ID == item_id);
      //   if (index === -1) {
      //     // TODO Error handling
      //     // ? For testing this, change the active cart while on the edit product page
      //     throw new Error(
      //       "The item you are trying to edit was not found in your current cart."
      //     );
      //   }
      //   const lineItem = { ...cart.Lines[index] };

      //   // const lineItem = cart.Lines
      //   // Check if the item is not a stacked moulding
      //   // if (cart.Lines[params.editCart].Type !== "stack")
      //   if (lineItem.Type !== PT.STACK) {
      //     const item = { ...cart.Lines[index] };
      //     // Set the moulding as the search value
      //     setSearchValue(item.SubItems[0].Moulding);

      //     // Set store general data:
      //     // TODO - check, is this handling PT.BOX & PT.CORNERSAMPLE ? If not, define below..
      //     setOrderData(
      //       produce((data) => {
      //         data.selected.moulding = item.SubItems[0].Moulding;
      //         data.itemIndex = 0;
      //         data.selected.shipsFrom = item.Plant;
      //         data.selected.productType = item.Type;
      //         data.selected.stacked = false;
      //         data.selected.quantity = item.Quantity;
      //       })
      //     );
      //     if (
      //       item.Type === PT.JOIN ||
      //       item.Type === PT.CHOP ||
      //       item.Type === PT.MIRROR
      //     ) {
      //       setOrderData(
      //         produce((data) => {
      //           data.selected.width = mergeAsNum(
      //             item.SubItems[0].Width,
      //             item.SubItems[0].WidthFraction
      //           );
      //           data.selected.height = mergeAsNum(
      //             item.SubItems[0].Length!,
      //             item.SubItems[0].LengthFraction
      //           );
      //           data.selected.sizing = item.SubItems[0].Layer as "I" | "O";
      //           if (item.Type === PT.JOIN || item.Type === PT.CHOP) {
      //             data.selected.allowance = item.Allowance;
      //             if (item.SubItems[0].Orientation) {
      //               data.selected.orientation = item.SubItems[0].Orientation;
      //             }
      //           }
      //           if (item.Type === PT.CHOP) {
      //             // const i = item as ChopLineItem
      //             data.selected.wedgeLock = item.SubItems[0].Wedge;
      //           }
      //           if (item.Type === PT.MIRROR) {
      //             data.selected.glassType = item.Bevel;
      //           }
      //         })
      //       );
      //     } else if (item.Type === PT.RAIL) {
      //       setOrderData(
      //         produce((data) => {
      //           data.selected.railLength = mergeAsNum(
      //             item.SubItems[0].Length,
      //             item.SubItems[0].LengthFraction
      //           );
      //           data.selected.allowance = item.Allowance;
      //           data.selected.sizing = item.SubItems[0].Layer as "I" | "O";
      //           if (item.SubItems[0].Orientation) {
      //             data.selected.orientation = item.SubItems[0].Orientation;
      //           }
      //         })
      //       );
      //     } else if (item.Type === PT.LENGTH) {
      //       const optionsExcludingOther = cuttingInstrOptions
      //         .slice(0, -1)
      //         .map((item) => {
      //           return item.value;
      //         });

      //       setOrderData(
      //         produce((data) => {
      //           data.selected.linearFt = item.SubItems[0].Length;
      //           data.selected.cuttingInstr = optionsExcludingOther.includes(
      //             item.Cutting
      //           )
      //             ? item.Cutting
      //             : "other";
      //           data.selected.specialCuttingInstr =
      //             optionsExcludingOther.includes(item.Cutting)
      //               ? ""
      //               : item.Cutting;
      //         })
      //       );
      //     } else if (item.Type === PT.CONTRACT) {
      //       setOrderData("selected", "linearFt", item.SubItems[0].Length);
      //     } else if (item.Type === PT.PHOTOFRAME) {
      //       setOrderData(
      //         "selected",
      //         item.Type,
      //         item.SubItems[0].SKU.split("-")[1]
      //       );
      //     } else if (item.Type === PT.GALLERYFRAME) {
      //       setOrderData(
      //         "selected",
      //         item.Type,
      //         item.SubItems[0].SKU.split("-")[1].split("M")[0]
      //       );
      //     }
      //   } else if (lineItem.Type === PT.STACK) {
      //     const item = [...cart.Lines[index].SubItems].reduce(
      //       (memo, item) => {
      //         if (item.Layer === "O") {
      //           memo.stacks.outside = item;
      //         } else if (item.Layer === "I") {
      //           memo.stacks.inside = item;
      //         } else if (item.Layer === "2") {
      //           memo.stacks.middle = item;
      //         }
      //         // if any of the layers are wedged,
      //         if (item.Wedge) {
      //           memo.Wedged = "TRUE";
      //         }
      //         return memo;
      //       },
      //       {
      //         ...cart.Lines[index],
      //         stacks: {} as Record<string, any>,
      //         Wedged: false as false | string, //TODO - fix this, where am I relying on false or 'TRUE' ?
      //       }
      //     ) as StackLineItem & {
      //       stacks: Record<
      //         "outside" | "inside" | "middle",
      //         StackLineItem["SubItems"][number]
      //       >;
      //       Wedged: WedgeOption | "TRUE" | undefined; //TODO - fix this, where am I relying on 'TRUE' ?
      //     };

      //     setSearchValue(item.stacks.inside.Moulding);

      //     setOrderData(
      //       produce((data) => {
      //         data.selected.moulding = item.stacks.inside.Moulding;
      //         data.itemIndex = 0;
      //         data.selected.shipsFrom = item.Plant;
      //         data.selected.stackCount = item.SubItems.length;
      //         data.selected.stacked = true;
      //         data.selected.productType =
      //           item.Type +
      //           capitalize(item.Operation) +
      //           item.SubItems.length.toString();
      //         data.selected.stacks.outsideDetails.sku =
      //           item.stacks.outside.Moulding;
      //         if (item.SubItems.length == 3) {
      //           data.selected.stacks.middleDetails.sku =
      //             item.stacks.middle.Moulding;
      //         }
      //         data.selected.sizing =
      //           item.FrameSize?.Sizing == "outside" ? "O" : "I";
      //         data.selected.width = mergeAsNum(
      //           item.FrameSize?.Width,
      //           item.FrameSize?.WidthFraction
      //         );
      //         data.selected.height = mergeAsNum(
      //           item.FrameSize.Length,
      //           item.FrameSize?.LengthFraction
      //         );
      //         data.selected.allowance = item.Allowance;
      //         data.selected.wedgeLock = item.Wedged;
      //       })
      //     );
      //   }
      //   if (lineItem.Tag) {
      //     setOrderData("selected", "tagName", lineItem.Tag);
      //   }
      //   return cart;
      // } else {
      //   // TODO Error handling
      //   throw new Error("Error retrieving cart details.");
      // }
    },
    // TODO Check if this is necessary - resource is blocked from firing initially..
    // @ts-expect-error
    {
      initialValue: {},
      ssrLoadFrom: "initial",
    }
  );

  onMount(async () => {
    // if in edit mode, manually trigger the cart resource, which in turn ensures an empty
    // orderData store, and then sets the values with the return value from cart/line id
    if (params.editCart) {
      await getCart();
      return;
    }

    if (!params.sku) return;

    if (params.sku) {
      setSearchValue(params.sku);
      setOrderData("selected", "moulding", params.sku);
      setOrderData("itemIndex", 0);
    }
    if (params.shipsFrom) {
      setOrderData("selected", "shipsFrom", params.shipsFrom);
    }

    if (params.type) {
      if (params.type == PT.PHOTOFRAME || params.type == PT.GALLERYFRAME) {
        if (!params.size) {
          setOrderData("selected", "productType", params.type);
        } else {
          setOrderData("selected", "productType", params.type);
          setOrderData("selected", params.type, params.size);
        }
      } else {
        setOrderData("selected", "productType", params.type);
      }
    }
    if (params.orientation) {
      setOrderData("selected", "orientation", params.orientation);
    }

    setTimeout(() => {
      setParams({
        shipsFrom: undefined,
        type: undefined,
        size: undefined,
      });
    }, 500);
  });

  onCleanup(() => {
    setOrderData({ ...getDefaultStore() });
  });

  return (
    <>
      {/* TODO - Error-Boundary */}
      <ErrorBoundary
        fallback={(err, reset) => <GenericError error={err} reset={reset} />}
      >
        <ItemOrderContext.Provider
          value={{ orderData, setOrderData, searchValue }}
        >
          {/* HEADER */}
          <Show
            when={(isPartner() && permission.PLACEORDER) || !isPartner()}
            fallback={
              <div class=" py-10 px-2">
                <div class="bg-roma-grey rounded-md max-w-2xl min-h-[60vh] mx-auto shadow-sm flex items-center justify-center px-4">
                  <p class="max-w-md text-center">
                    Your Roma Partner account is not set up to place orders.
                    Please{" "}
                    <A href="/support" class="text-roma-blue">
                      contact support
                    </A>{" "}
                    for more details.
                  </p>
                </div>
              </div>
            }
          >
            <div class="w-full bg-faint-blue pt-10 pb-5 border-y boundary-outer">
              <div class="max-w-2xl mx-auto flex flex-col gap-2">
                {/* <Show when={import.meta.env.DEV}>
                  <button
                    class="text-orange-500 text-left text-xl"
                    onClick={() => setShowStoreValues(!showStoreValues())}
                  >
                    VALUES
                  </button>
                </Show> */}
                <Show when={orderData.editProduct == true && cart()}>
                  <A
                    href={`/checkout/${session()?.cart_id}`}
                    class="text-roma-blue text-sm"
                  >
                    <div class="flex items-end gap-1">
                      <Icon path={chevronLeft} class="w-4 -ml-1" />
                      <span class="block leading-none">Back to cart</span>
                    </div>
                  </A>
                </Show>
                <div class="flex justify-between items-end ">
                  <h3 class="text-xl font-bold">
                    {orderData.editProduct
                      ? "Edit your order"
                      : "Place an order"}
                  </h3>
                </div>
                <Switch>
                  <Match when={cart.loading}>
                    <BaseLoader width="10" height="10" /> Loading cart
                    details....
                  </Match>
                  <Match
                    when={
                      orderData.selected.moulding &&
                      orderData.selected.stacked === false
                    }
                  >
                    <div class="flex justify-between items-center">
                      <h3 class="text-roma-dark-grey font-bold">
                        Selected Moulding
                      </h3>
                      <button
                        class="text-roma-blue font-bold"
                        onClick={() => {
                          setOrderData("selected", "moulding", undefined);
                          setSearchValue("");
                          setShowSearchResults(false);
                          setParams({ sku: undefined });
                        }}
                      >
                        <span>Change</span>
                      </button>
                    </div>
                    <div class="border rounded-sm">
                      <Show
                        when={products.latest?.Results?.[orderData.itemIndex]}
                        fallback={<ProductPlaceholder />}
                        keyed
                      >
                        {(prod) => (
                          <ProductThumbnail
                            collectionName={prod.Collection}
                            width={prod.Width}
                            colour={prod.ColourDescription}
                            sku={prod.SKU}
                            productCategory={prod.Category}
                            disableHover={true}
                            category={
                              orderData.selected.productType ===
                                PT.PHOTOFRAME ||
                              orderData.selected.productType === PT.GALLERYFRAME
                                ? orderData.selected.productType
                                : undefined
                            }
                          />
                        )}
                      </Show>
                    </div>
                    <Show when={orderData.isDiscontinued == true}>
                      <InfoBox type="warning">
                        This moulding has been discontinued - Length orders over
                        50 ft will have a{" "}
                        {orderData.currentProduct?.DiscontinuedDiscount
                          ? `${orderData.currentProduct.DiscontinuedDiscount}% discount`
                          : "discount"}{" "}
                        applied!
                      </InfoBox>
                    </Show>
                  </Match>
                  <Match when={!orderData.selected.moulding}>
                    <>
                      <p class="text-roma-dark-grey font-light">
                        Add products to your cart by moulding number
                      </p>
                      <div class="relative">
                        <TextFieldInput
                          name="productSearch"
                          id="productSearch"
                          placeholder="Enter a moulding number"
                          icon={magnifyingGlass}
                          onChange={(val) => {
                            search(val);
                            setShowSearchResults(true);
                          }}
                        />
                        <Show when={showSearchResults()}>
                          <div class="border rounded-sm absolute inset-x-0 max-h-96 overflow-y-auto bg-white z-10">
                            <div class="divide-y">
                              <For
                                each={products.latest?.Results}
                                fallback={
                                  <div class="flex justify-center items-center">
                                    <p class="text-roma-medium-grey py-10 px-5 text-center">
                                      No products found, enter another moulding
                                      number or{" "}
                                      <A href="/shop" class="text-roma-blue">
                                        browse all of our products
                                      </A>{" "}
                                    </p>
                                  </div>
                                }
                              >
                                {(item, index) => (
                                  <ProductThumbnail
                                    collectionName={item.Collection}
                                    width={item.Width}
                                    colour={item.ColourDescription}
                                    productCategory={item.Category}
                                    sku={item.SKU}
                                    onClick={() => {
                                      setOrderData(
                                        "selected",
                                        "moulding",
                                        item.SKU
                                      );
                                      setOrderData(
                                        "selected",
                                        "mouldingWidth",
                                        item.Width
                                      );
                                      setOrderData("itemIndex", index());
                                      setOrderData(
                                        produce((store) => {
                                          store.selected.name = item.Name;
                                          store.selected.collection =
                                            item.Collection;
                                          store.selected.category =
                                            item.Category;
                                        })
                                      );
                                      if (
                                        orderData.selected.productType &&
                                        !orderData.availableAs?.includes(
                                          orderData.selected.productType
                                        )
                                      ) {
                                        setOrderData(
                                          "selected",
                                          "productType",
                                          undefined
                                        );
                                      }
                                      track(EventType.Event, {
                                        category: "order",
                                        action: "search_select",
                                        value: item.SKU,
                                      });
                                    }}
                                  />
                                )}
                              </For>
                            </div>
                          </div>
                        </Show>
                      </div>
                    </>
                  </Match>
                  <Match
                    when={
                      orderData.selected.productType &&
                      [
                        "stackJoin2",
                        "stackChop2",
                        "stackMirror2",
                        "stackJoin3",
                        "stackChop3",
                        "stackMirror3",
                      ].includes(orderData.selected.productType)
                    }
                  >
                    {/* NOTICE OF STACK LAYER ORDERING UPDATE */}
                    <div class="bg-white rounded-lg shadow-md p-3 sm:p-4 text-sm relative border border-roma-blue mt-2">
                      <div class="bg-roma-blue rounded-full aspect-square w-7 absolute -left-3 -top-3 shadow-xl text-white flex justify-center items-center">
                        <Icon path={questionMarkCircle} class="w-6" />
                      </div>
                      <p>
                        Please note, we've changed the ordering of input for
                        stacked frames. Please start with your inside/innermost
                        moulding.
                      </p>
                    </div>
                    {/* END NOTICE OF STACK LAYER ORDERING UPDATE */}
                    <Switch>
                      <Match
                        when={[
                          "stackJoin2",
                          "stackChop2",
                          "stackMirror2",
                        ].includes(
                          orderData.selected.productType! // this is known to exist bc of surrounding Match
                        )}
                      >
                        <StackSearchTwo />
                      </Match>
                      <Match
                        when={[
                          "stackJoin3",
                          "stackChop3",
                          "stackMirror3",
                        ].includes(
                          orderData.selected.productType! // this is known to exist bc of surrounding Match
                        )}
                      >
                        <StackSearchThree />
                      </Match>
                    </Switch>
                  </Match>
                </Switch>
              </div>
            </div>
          </Show>
          {/* END HEADER */}
          {/* border border-orange-500 min-h-[60vh] */}
          <div class="boundary-outer">
            <main class=" bg-white pt-5 pb-20 max-w-2xl mx-auto  flex flex-col gap-5 ">
              {/* HERE */}
              {/* <ErrorBoundary fallback={(err) => <div>Error... {err}</div>}> */}
              <Show
                when={
                  orderData.currentProduct &&
                  orderData.selected.moulding &&
                  !cart.loading
                }
                fallback={
                  <div class="w-[90%] mx-auto border border-gray-200 rounded-lg relative h-50v max-h-[600px]">
                    <div class="flex flex-col gap-5 p-8">
                      <Motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{
                          duration: 1.1,
                          easing: "ease-in-out",
                          delay: 0.5,
                        }}
                        class="text-roma-dark-grey"
                      >
                        <span class="font-medium">Product Type</span>
                        <div class="border h-10 border-gray-400 flex justify-end items-center">
                          <Icon path={chevronDown} class="w-6 mr-3" />
                        </div>
                      </Motion.div>
                      <Motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{
                          duration: 1.1,
                          easing: "ease-in-out",
                          delay: 1,
                        }}
                        class="text-roma-dark-grey"
                      >
                        <span class="font-medium">Ships From</span>
                        <div class="border h-10 border-gray-400 flex justify-end items-center">
                          <Icon path={chevronDown} class="w-6 mr-3" />
                        </div>
                      </Motion.div>
                    </div>
                    <div class="absolute inset-0 bg-gray-100/25 rounded-lg flex justify-center items-center backdrop-blur-sm">
                      <Show
                        when={isPartner()}
                        fallback={
                          <button
                            class="border rounded-lg py-3 px-5 bg-white hover:bg-gray-100"
                            onClick={() => {
                              setParams({ signIn: true });
                            }}
                          >
                            Sign in to begin order
                          </button>
                        }
                      >
                        <p class="text-roma-medium-grey">
                          Search for a moulding to begin order
                        </p>
                      </Show>
                    </div>
                  </div>
                }
              >
                <Suspense
                  fallback={
                    <div class="flex justify-center p-6">
                      <BaseLoader width="10" height="10" />
                    </div>
                  }
                >
                  <TypeSelector />
                  <Switch>
                    <Match when={orderData.selected.productType === PT.LENGTH}>
                      <Length />
                    </Match>
                    <Match
                      when={orderData.selected.productType === PT.CONTRACT}
                    >
                      <Contract minFootage={3000} />
                    </Match>
                    <Match when={orderData.selected.productType === PT.JOIN}>
                      <Join />
                    </Match>
                    <Match when={orderData.selected.productType === PT.CHOP}>
                      <Chop />
                      {/* TEST THAT WEDGELOCKS ARE WORKING PROPERLY ONCE ORDERSUMMARY COMP IS ADDED... */}
                    </Match>
                    <Match when={orderData.selected.productType === PT.BOX}>
                      <QtyCounter />
                      {/* Consider wrapping this in a BOX component, with the logic that requires freight consent inside. */}
                      {/* Currently that logic is being handled in OrderSummary.. */}
                    </Match>
                    <Match when={orderData.selected.productType === PT.RAIL}>
                      <Rail />
                      {/* TODO - No check on max/min size, similar to Join/Chop ?? */}
                    </Match>
                    <Match
                      when={orderData.selected.productType === PT.PHOTOFRAME}
                    >
                      <PhotoFrame />
                    </Match>
                    <Match
                      when={orderData.selected.productType === PT.GALLERYFRAME}
                    >
                      <GalleryFrame />
                    </Match>
                    <Match
                      when={orderData.selected.productType === PT.CORNERSAMPLE}
                    >
                      <CornerSample />
                    </Match>
                    <Match when={orderData.selected.productType === PT.MIRROR}>
                      TODO
                    </Match>
                    <Match
                      when={[
                        "stackJoin2",
                        "stackJoin3",
                        "stackChop2",
                        "stackChop3",
                        "stackMirror2",
                        "stackMirror3",
                      ].includes(orderData.selected.productType!)}
                    >
                      <Stacked />
                      {/* TEST THE JOIN WARNING LOGIC, MAKE SURE CALCULATIONS ARE CORRECT */}
                    </Match>
                  </Switch>
                  {/* BACKORDER ACKNOWLEDGE */}
                  <Show
                    when={
                      orderData.selected.showBackorderBox == true &&
                      !orderData.isDiscontinued
                    }
                  >
                    <BackOrderAcknowledge />
                  </Show>
                  {/* CANCEL BACKORDER ACKNOWLEDGE */}
                  <Show
                    when={
                      orderData.selected.showCancelBackorderBox &&
                      !orderData.isDiscontinued
                    }
                  >
                    <CancelBackOrderAcknowledge />
                  </Show>
                  {/* BLOCK CHECKOUT - DISCONTINUED / DISCONTINUED STACK */}
                  <Show when={orderData.blockCheckout}>
                    <Switch>
                      <Match when={orderData.isDiscontinued}>
                        <DiscontinuedAcknowledge />
                      </Match>
                      <Match
                        when={orderData.selected.productType?.includes(
                          PT.STACK
                        )}
                      >
                        <DiscontinuedStackWarning />
                      </Match>
                    </Switch>
                  </Show>
                  {/* FREIGHT CHARGE ACKNOWLEDGE */}
                  <Show when={orderData.selected.showFreightBox}>
                    <FreightChargeAcknowledge />
                  </Show>
                  {/* TAG NAME */}
                  <Show when={orderData.selected.productType}>
                    <TagName />
                  </Show>
                  {/* ORDER SUMMARY */}
                  <Show
                    when={
                      orderData.selected.shipsFrom &&
                      orderData.selected.productType
                    }
                  >
                    <OrderSummary />
                    <button
                      type="button"
                      class="text-roma-blue text-sm ml-auto"
                      onClick={() => {
                        setParams({
                          sku: undefined,
                          editCart: undefined,
                          type: undefined,
                          size: undefined,
                          shipsFrom: undefined,
                        });
                        setOrderData({ ...getDefaultStore() });
                        setShowSearchResults(false);
                      }}
                    >
                      Clear
                    </button>
                  </Show>
                </Suspense>
              </Show>
              {/* </ErrorBoundary> */}
              <StoreValuesHelper
                opened={showStoreValues}
                close={() => setShowStoreValues(false)}
              />
            </main>
          </div>
        </ItemOrderContext.Provider>
      </ErrorBoundary>
    </>
  );
}

// * Types for the orderData store * //
// TODO - rough, some properties may need to be changed, many can probably be removed
export interface OrderData_StackDetails {
  sku: string | undefined;
  mouldingWidth: number | undefined;
  mouldingHeight: number | undefined;
  width: number | undefined;
  height: number | undefined;
  floater: boolean | undefined;
  fillet: boolean | undefined;
  discontinued: boolean | undefined;
  stockLevel: number | undefined;
  category: string | undefined;
}

export interface OrderData_Stacks {
  outside: string | undefined;
  middle: string | undefined;
  outsideDetails: OrderData_StackDetails;
  middleDetails: OrderData_StackDetails;
  insideDetails: OrderData_StackDetails;
}

interface OrderData_Selected {
  // productType: string | undefined;
  productType: string | undefined; // should be `${PT}`, but this may cause an issue for stacks, as current system sets a stack as stackJoin2, etc..
  // cuttingInstr: string | undefined;
  cuttingInstr: CuttingOption | undefined;
  // orientation: string | undefined;
  orientation: OrientationOption | undefined;
  // sizing: "I" | "O" | string | undefined;
  sizing: "I" | "O" | undefined;
  // allowance: string | undefined;
  allowance: AllowanceOption | undefined;
  // wedgeLock: string | boolean | undefined; //"single"  | "double" | "none??"
  wedgeLock: WedgeOption | "TRUE" | undefined; //"single"  | "double" | "none??"
  // glassType: string | undefined;
  glassType: BevelOption | undefined;
  moulding: string | undefined;
  name: string | undefined;
  category: string | undefined;
  collection: string | undefined;
  stacked: boolean;
  shipsFrom: string | undefined;
  shipsFromPlant: string;
  shipsFromStockLevel: number | undefined;
  backorderConsent: boolean | undefined;
  showBackorderBox: boolean;
  showCancelBackorderBox: boolean;
  cancelBackorderConsent: boolean | undefined;
  freightChargeConsent: boolean | undefined;
  showFreightBox: boolean;
  oversizeWarning: boolean;
  linearFt: number | undefined;
  specialCuttingInstr: string | undefined;
  cuttingPositions: any[] | undefined;
  width: number | undefined;
  height: number | undefined;
  tagName: string | undefined;
  railLength: number | undefined;
  photoFrame: string | undefined;
  galleryFrame: string | undefined;
  quantity: number;
  amount: number | undefined;
  stackCount: number | undefined;
  stackOperation: string | undefined;
  stackContainsZeroStockItem: boolean | undefined;
  stackStockCheckoutStatus: string | undefined;
  stacks: OrderData_Stacks;
  mouldingWidth: number | undefined;
  validDimensions: boolean | undefined;
}

interface OrderData_Product {
  SKU: string;
  Name: string;
  Collection: string;
  Category: string;
  ColourDescription: string;
  AvailableAs: string[];
  Width: number;
  Height: number;
  Rabbet: number;
  Discontinued: boolean;
  DiscontinuedDiscount?: number;
  Floater: boolean;
  Profile: string;
  ProductType: string;
}

export interface OrderStore {
  products: OrderData_Product[] | undefined;
  allProducts: OrderData_Product[] | undefined;
  itemIndex: number;
  currentProduct: OrderData_Product | undefined;
  availableAs: string[] | undefined;
  width: number | undefined;
  mouldingHeight: number | undefined;
  isDiscontinued: boolean | undefined;
  isFloater: boolean | undefined;
  isStretcher: boolean | undefined;
  blockCheckout: boolean;
  editProduct: boolean;
  defaultShipsFrom: string | undefined;
  shipsFromLength: number | undefined;
  selected: OrderData_Selected;
}
